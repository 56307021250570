import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'Emilus';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';
export const REDIRECT_URL_KEY = 'redirect'


export const UNAUTHENTICATED_ENTRY = '/login'

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR,
	blankLayout: false
};

const role = localStorage.getItem('role');
let AUTHENTICATED_ENTRY;

switch (role) {
  case 'restaurant':
    AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/users/restaurant`;
    break;
  case 'delivery':
    AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/users/deliveries`;
    break;
  case 'supper':
    AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/dashboards/default`;
    break;
//   default:
//     // Handle any default or fallback logic if needed
//     AUTHENTICATED_ENTRY = `${APP_PREFIX_PATH}/default/path`;
//     break;
}

export { AUTHENTICATED_ENTRY };
