import { message } from "antd";
import axios from "../api/axiosConfig";
import Swal from 'sweetalert2';




// PRODUCTS

export const getProducts = async () => {
    const { data } = await axios.get(`/products`);
    return data;
};


export const getProduct = async (id) => {
    const { data } = await axios.get(`/products/${id}`);
    return data;
};


export const updateProducts = async (id , formData) => {

    await axios.post(`/products/${id}`, formData).then(({ data }) => {
        message.success(data.message);
    }).catch(({ response }) => {
        if (response.status === 422) {
            message.error(response.data.message);
        } else {
            message.error(response.data.message);
        }
    })
};


export const DeleteProducts = async (id , setFilteredProducts , filteredProducts) => {
    Swal.fire({
        title: "Do you want to Delete ?",
        showCancelButton: true,
        confirmButtonText: "Delete"
    }).then(async (result) => {

        if (result.isConfirmed) {
            const { data } = await axios.delete(`/products/${id}`,).then(({ data }) => {
                setFilteredProducts(filteredProducts.filter(product => product.id !== id));
                
                Swal.fire({
                    icon: "success",
                    text: data.message
                })
            }).catch(({ response }) => {
                if (response.status === 422) {
                    Swal.fire({
                        text: response.data.errors,
                        icon: "error"
                    })
                } else {
                    Swal.fire({
                        text: response.data.message,
                        icon: "error"
                    })
                }
            })
        }
    });

}


export const hideProducts = async (id, setFilteredProducts, filteredProducts) => {
   

    Swal.fire({
        title: "Do you want to Hide?",
        showCancelButton: true,
    }).then(async (result) => {
        if (result.isConfirmed) {
            axios.post(`/products/${id}/hide`)
                .then(({ data }) => {
                    // Update the product status in the array
                    const updatedProducts = filteredProducts.map(product => 
                        product.id === id ? { ...product, status: 'hide' } : product
                    );

                    // Update the state with the new array
                    setFilteredProducts(updatedProducts);

                    Swal.fire({
                        icon: "success",
                        text: data.message
                    });
                })
                .catch(({ response }) => {
                    if (response.status === 422) {
                        Swal.fire({
                            text: response.data.errors,
                            icon: "error"
                        });
                    } else {
                        Swal.fire({
                            text: response.data.message,
                            icon: "error"
                        });
                    }
                });
        }
    });
};



export const activeProducts = async (id, setFilteredProducts, filteredProducts) => {
    Swal.fire({
        title: "Do you want to Active?",
        showCancelButton: true,
    }).then(async (result) => {
        if (result.isConfirmed) {
            axios.post(`/products/${id}/active`)
                .then(({ data }) => {
                    // Update the product status in the array
                    const updatedProducts = filteredProducts.map(product => 
                        product.id === id ? { ...product, status: 'active' } : product
                    );

                    // Update the state with the new array
                    setFilteredProducts(updatedProducts);

                    Swal.fire({
                        icon: "success",
                        text: data.message
                    });
                })
                .catch(({ response }) => {
                    if (response.status === 422) {
                        Swal.fire({
                            text: response.data.errors,
                            icon: "error"
                        });
                    } else {
                        Swal.fire({
                            text: response.data.message,
                            icon: "error"
                        });
                    }
                });
        }
    });
}



// USERS 



export const getUsers = async () => {
    const { data } = await axios.get(`/users`);
    return data;
};


export const getUser = async (id) => {
    const { data } = await axios.get(`/users/${id}`);
    return data;
};


export const getUsersRestaurants = async () => {
    const { data } = await axios.get(`/users/getUsersRestaurants`);
    return data;
};

export const getUsersDeliveries = async () => {
    const { data } = await axios.get(`/users/getUsersDeliveries`);
    return data;
};


export const addUser = async (values) => {
    await axios.post(`/register`, values).then(({ data }) => {
        message.success(data.message);
    }).catch(({ response }) => {
        if (response.status === 422) {
            message.error(response.data.message);
        } else {
            message.error(response.data.message);
        }
    })
};


export const updateUser = async (id, data) => {
    // const { data } = await axios.put(`/users/${id}`, data, {
    //     headers: {
    //         'Content-Type': 'multipart/form-data',
    //     },
    // });
    // return data;



    await axios.put(`/users/${id}`, data).then(({ data }) => {
        message.success(data.message);
    }).catch(({ response }) => {
        if (response.status === 422) {
            message.error(response.data.message);
        } else {
            message.error(response.data.message);
        }
    })
};



export const DeleteUser = async (id, setUsers, users) => {
    Swal.fire({
        title: "Do you want to Delete?",
        showCancelButton: true,
        confirmButtonText: "Delete",
        cancelButtonText: "Cancel"
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                const { data } = await axios.delete(`/users/${id}`);
                
                // Update users state to filter out the deleted user
                setUsers(users.filter(user => user.id !== id));
                
                Swal.fire({
                    icon: "success",
                    text: data.message
                });
            } catch (error) {
                // Handle errors based on status code
                if (error.response.status === 422) {
                    Swal.fire({
                        text: error.response.data.errors,
                        icon: "error"
                    });
                } else {
                    Swal.fire({
                        text: error.response.data.message || 'An error occurred',
                        icon: "error"
                    });
                }
            }
        }
    });
};


export const getUserWithOrders = async (id , type) => {
    const { data } = await axios.get(`/orders/${type}/${id}`);
    return data;
};



export const updateLocationUser = async (formData) => {

    await axios.post(`/update-location`, formData).then(({ data }) => {
        message.success(data.message);
    }).catch(({ response }) => {
        if (response.status === 422) {
            message.error(response.data.message);
        } else {
            message.error(response.data.message);
        }
    })
};



// ORDERS 


export const getOrderResturant = async (userId) => {
    const { data } = await axios.get(`/orderResturant/${userId}`);
    return data;
};
export const orderResturantAccepted = async (userId) => {
    const { data } = await axios.get(`/orderResturantAccepted/${userId}`);
    return data;
};


export const getOrderDelivered = async (userId) => {
    const { data } = await axios.get(`/orderDelivery/${userId}`);
    return data;
};


export const getOrderDeliveredWithResturant = async (userId) => {
    const { data } = await axios.get(`/order-user-details/${userId}`);
    return data;
};


export const getAllOrdersWithProducts = async (userId) => {
    const { data } = await axios.get(`/orders-with-products`);
    return data;
};


export const updateStatusOrder = async (IdOrder, Status) => {
    const { data } = await axios.post(`/statusOrder/${IdOrder}/${Status}`);
    return data;
};

export const getProductsByOrderId = async (id) => {
    const { data } = await axios.get(`/getorders/${id}/products`);
    return data;
};




// ACCEPT OR REFUSE ORDERS


export const ResponseOrders = async (notificationID, orderId, response, userID, authToken, setOrders) => {
    try {
        const { data } = await axios.post(
            `/orders/${notificationID}/${orderId}/${userID}/response`, 
            { response }, 
            { headers: { Authorization: `Bearer ${authToken}` } }
        );
        setOrders(prevOrders => prevOrders.filter(order => order.id !== notificationID));
        message.success(data.message);
    } catch (error) {
        // Log the error response
        console.error('Error Response:', error.response);

        // Show the error message
        message.error(error.response?.data?.message || 'An error occurred');
    }
};



export const ResponseDelivery = async (notificationID, orderId, response, userID, authToken, setOrders) => {
    try {
        const { data } = await axios.post(
            `/delivery/${notificationID}/${orderId}/${userID}/response`, 
            { response }, 
            { headers: { Authorization: `Bearer ${authToken}` } }
        );
        setOrders(prevOrders => prevOrders.filter(order => order.id !== notificationID));
        message.success(data.message);
    } catch (error) {
        // Log the error response
        console.error('Error Response:', error.response);

        // Show the error message
        message.error(error.response?.data?.message || 'An error occurred');
    }
};



//  TRANSITION 

export const calculateTotalSoldCosts = async () => {
    const { data } = await axios.get('/total-sold-costs');
    return data;
};


export const calculateTotalSales = async () => {
    const { data } = await axios.get('/total-sales');
    return data;
};


export const calculateTotalRevenue = async () => {
    const { data } = await axios.get('/total-revenue');
    return data;
};


export const getTotalQuantitySold = async () => {
    const { data } = await axios.get('/total-quantity-sales');
    return data;
};

export const getLatestTransitions = async () => {
    const { data } = await axios.get('/latest-transitions');
    return data;
};

export const getLatestOrders = async () => {
    const { data } = await axios.get('/order/latest');
    return data;
};

export const getProductSalesInfo = async () => {
    const { data } = await axios.get('/sales-info');
    return data;
};


export const getTopSellingProducts = async () => {
    const { data } = await axios.get('/top-three-products');
    return data;
};

export const getLeastSellingProducts = async () => {
    const { data } = await axios.get('/least-selling-products');
    return data;
};


export const getDailySalesVolume = async () => {
    const { data } = await axios.get('/daily-sales-volume');
    return data;
};


export const getWeeklySalesVolume = async () => {
    const { data } = await axios.get('/sales-weekly');
    return data;
};

export const getSalesVolumeByMonth = async () => {
    const { data } = await axios.get('/sales-volume-by-month');
    return data;
};


export const getDeliveryUsersWithTotalPrice = async () => {
    const { data } = await axios.get('/users-with-total-price');
    return data;
};


export const getTotalAcceptedOrdersByUser = async () => {
    const { data } = await axios.get('/restaurant-orders/accepted-total');
    return data;
};





