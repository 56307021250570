import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import store from './store';
import Layouts from './layouts';
import { THEME_CONFIG } from './configs/AppConfig';
import './lang';
import { updateLocationUser } from 'request/request';

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  const role = localStorage.getItem('role');

  useEffect(() => {
    if (role !== 'delivery') {
      return
    }

    const updateLocation = async () => {
      const formData = new FormData()
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const { latitude, longitude } = position.coords;
          try {
            
            formData.append('latitude', latitude)
            formData.append('longitude', longitude)
            const response =  await updateLocationUser(formData)

            if (response.data.updated) {
              console.log('Location updated in the database.');
            } else {
              console.log('Location not updated.');
            }
          } catch (error) {
            console.error('Error updating location:', error);
          }
        });
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    };

    // Update location every 5 minutes if role is "delivery"
    const intervalId = setInterval(updateLocation, 300000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [role]);

  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={THEME_CONFIG.currentTheme}
            insertionPoint="styles-insertion-point"
          >
            <Layouts />
          </ThemeSwitcherProvider>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
