import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig';

const PublicRoute = () => {
  // Get the auth_token from localStorage
  const authToken = localStorage.getItem('auth_token');
  
  // If the auth_token exists, parse it and extract the token, otherwise return null
  const token = authToken ? JSON.parse(authToken).token : null;

  return token ? <Navigate to={AUTHENTICATED_ENTRY} /> : <Outlet />;
};

export default PublicRoute;
