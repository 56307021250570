import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'login-1',
        path: `${AUTH_PREFIX_PATH}/login-1`,
        component: React.lazy(() => import('views/auth-views/authentication/login-1')),
    },
   
   
    
   
    {
        key: 'error-page-1',
        path: `${AUTH_PREFIX_PATH}/error-page-1`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-1')),
    },
    {
        key: 'error-page-2',
        path: `${AUTH_PREFIX_PATH}/error-page-2`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-2')),
    },
]


export const protectedRoutes = [

    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'dashboard.sales',
        path: `${APP_PREFIX_PATH}/dashboards/sales`,
        component: React.lazy(() => import('views/app-views/dashboards/sales')),
    },
    {
        key: 'apps',
        path: `${APP_PREFIX_PATH}/apps`,
        component: React.lazy(() => import('views/app-views/apps')),
    },
  
   

    
    {
        key: 'apps.ecommerce',
        path: `${APP_PREFIX_PATH}/apps/ecommerce`,
        component: React.lazy(() => import('views/app-views/apps/e-commerce')),
    },
    {
        key: 'apps.ecommerce.add-product',
        path: `${APP_PREFIX_PATH}/apps/add-product`,
        component: React.lazy(() => import('views/app-views/apps/e-commerce/add-product')),
    },
    {
        key: 'apps.ecommerce.edit-product',
        path: `${APP_PREFIX_PATH}/apps/ecommerce/edit-product/:id`,
        component: React.lazy(() => import('views/app-views/apps/e-commerce/edit-product')),
    },
    {
        key: 'apps.ecommerce.product-list',
        path: `${APP_PREFIX_PATH}/apps/product-list`,
        component: React.lazy(() => import('views/app-views/apps/e-commerce/product-list')),
    },
    
    
    
  
  
  

    
    {
        key: 'components.chart',
        path: `${APP_PREFIX_PATH}/components/charts`,
        component: React.lazy(() => import('views/app-views/components/charts')),
    },
    {
        key: 'components.chart.apex-charts',
        path: `${APP_PREFIX_PATH}/components/charts/apex-charts`,
        component: React.lazy(() => import('views/app-views/components/charts/apex')),
    },
    {
        key: 'components.chart.chartjs',
        path: `${APP_PREFIX_PATH}/components/charts/chartjs`,
        component: React.lazy(() => import('views/app-views/components/charts/chartjs')),
    },
   
    {
        key: 'login-1',
        path: `/log`,
        component: React.lazy(() => import('views/auth-views/authentication/login-1')),
        meta: {
            blankLayout: true
        }
    },
   
    
    
    
    
    {
        key: 'error-page-1',
        path: `${APP_PREFIX_PATH}/error-page-1`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-1')),
        meta: {
            blankLayout: true
        }
    },
    {
        key: 'error-page-2',
        path: `${APP_PREFIX_PATH}/error-page-2`,
        component: React.lazy(() => import('views/auth-views/errors/error-page-2')),
        meta: {
            blankLayout: true
        }
    },
   
  
   
   


    {
        key: 'users.resturant-list',
        path: `${APP_PREFIX_PATH}/users/list`,
        component: React.lazy(() => import('views/app-views/users/list')),
    },

    {
        key: 'users.resturant-add',
        path: `${APP_PREFIX_PATH}/users/add`,
        component: React.lazy(() => import('views/app-views/users/Form')),
    },



    {
        key: 'resturant',
        path: `${APP_PREFIX_PATH}/users/resturant`,
        component: React.lazy(() => import('views/app-views/views-users/resturants')),
    },

    {
        key: 'deliveries',
        path: `${APP_PREFIX_PATH}/users/deliveries`,
        component: React.lazy(() => import('views/app-views/views-users/deliveries')),
    },


    {
        key: 'orders',
        path: `${APP_PREFIX_PATH}/orders/list`,
        component: React.lazy(() => import('views/app-views/orders')),
    },


    {
        key: 'orders-accepted-resturant',
        path: `${APP_PREFIX_PATH}/users/resturant/orders`,
        component: React.lazy(() => import('views/app-views/views-users/views/resturans')),
    },

    {
        key: 'orders-accepted-deliveries',
        path: `${APP_PREFIX_PATH}/users/deliveries/orders`,
        component: React.lazy(() => import('views/app-views/views-users/views/deliveries')),
    },


    {
        key: 'restaurants-List',
        path: `${APP_PREFIX_PATH}/users/restaurants/list`,
        component: React.lazy(() => import('views/app-views/views-admin/users/list')),
    },

    {
        key: 'restaurants-New',
        path: `${APP_PREFIX_PATH}/users/restaurants/new`,
        component: React.lazy(() => import('views/app-views/views-admin/users/Form')),
    },

    {
        key: 'delivery-List',
        path: `${APP_PREFIX_PATH}/users/delivery/list`,
        component: React.lazy(() => import('views/app-views/views-admin/delivery/list')),
    },

    {
        key: 'delivery-New',
        path: `${APP_PREFIX_PATH}/users/delivery/new`,
        component: React.lazy(() => import('views/app-views/views-admin/delivery/Form')),
    },

    {
        key: 'update-profile',
        path: `${APP_PREFIX_PATH}/settings/update-profile`,
        component: React.lazy(() => import('views/app-views/views-admin/setting/Form')),
    },

    {
        key: 'naw-admin',
        path: `${APP_PREFIX_PATH}/settings/new-admin`,
        component: React.lazy(() => import('views/app-views/views-admin/setting/FormAdd')),
    },








    
]